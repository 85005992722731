<template>
  <div>
    <div class="contact-form">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn class="brdr hidden-sm-and-up ma-2" @click="contact = true" small outlined :color="$blue" fab v-on="on">
            <v-icon v-if="sent == false" :color="$orange">mdi-email</v-icon>
            <v-icon v-if="sent == true" :color="$orange">mdi-email-check</v-icon>
          </v-btn>
          <v-btn class="brdr hidden-xs-only" @click="contact = true" outlined :color="$blue" large fab v-on="on">
            <v-icon v-if="sent == false" large :color="$orange">mdi-email</v-icon>
            <v-icon v-if="sent == true" large :color="$orange">mdi-email-check</v-icon>
          </v-btn>
        </template>
        <span class="tooltip">{{ locale == 'fi' ? 'Ota meihin yhteyttä!' : 'Contact us!' }}</span>
      </v-tooltip>
    </div>

    <template v-if="contact == true">
      <contactform :show="contact" @closed="closeForm"></contactform>
    </template>

    <template v-if="snackbar">
      <div class="text-center ma-2 hidden-sm-and-up">
        <v-snackbar :color="$blue" v-model="snackbar" multi-line top :timeout="5000">
          {{ locale == 'fi' ? 'Yhteydenottopyyntö lähetetty!' : 'Contact form sent!' }}
          <v-btn :color="$orange" text @click="snackbar = false">Ok</v-btn>
        </v-snackbar>
      </div>
      <div class="text-center ma-2 hidden-xs-only">
        <v-snackbar :color="$blue" v-model="snackbar" multi-line bottom :timeout="5000">
          {{ locale == 'fi' ? 'Yhteydenottopyyntö lähetetty!' : 'Contact form sent!' }}
          <v-btn :color="$orange" text @click="snackbar = false">Ok</v-btn>
        </v-snackbar>
      </div>
    </template>
    <template v-if="snackbar2">
      <div class="text-center ma-2 hidden-sm-and-up">
        <v-snackbar :color="$blue" v-model="snackbar2" multi-line top :timeout="5000">
          <p class="error--text">{{ locale == 'fi' ? 'Yhteydenottopyynnön lähettämisessä tapahtui virhe.' : 'There was an error with sending the contact form' }}</p>
          <v-btn :color="$orange" text @click="snackbar2 = false">Ok</v-btn>
        </v-snackbar>
      </div>
      <div class="text-center ma-2 hidden-xs-only">
        <v-snackbar :color="$blue" v-model="snackbar2" multi-line top :timeout="5000">
          <p class="error--text">{{ locale == 'fi' ? 'Yhteydenottopyynnön lähettämisessä tapahtui virhe.' : 'There was an error with sending the contact form' }}</p>
          <v-btn :color="$orange" text @click="snackbar2 = false">Ok</v-btn>
        </v-snackbar>
      </div>
    </template>
  </div>
</template>

<style scoped>
.brdr {
  border: 1px #f7941e solid;
  background-color: #002135;
}
.contact-form {
  position: fixed;
  bottom: 4%;
  right: 20px;
  z-index: 10;
  font-size: 22px;
  color: #f7941e;
  font-weight: 600;
}
</style>

<script>
export default {
  name: "ContactButton",
  components: {
    contactform: () => import("./ContactForm")
  },
  data() {
    return {
      contact: false,
      snackbar: false,
      snackbar2: false,
      sent: false
    };
  },
  methods: {
    closeForm(ok, error) {

      if(error){
          this.snackbar2 = true
      }

      if (ok) {
        this.sent = this.snackbar = true;
      }

      this.contact = false;
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>
